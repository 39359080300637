const Privacy = () => (
    <article className="container">
        <h1>Privacy Policy</h1>
        <p>The undersigned:

	 [name of Designer or design agency]having its registered office at ...................................................... in 	registered at the Chamber of Commerce under number 	referred to below as the “Designer” or the “Controller”;and
	. [name of contractor] having its registered office at ...................................................... in 	registered at the Chamber of Commerce under number 	legally represented by its [position] 	Mr/Ms 	referred to below as the “Contractor” or the “Processor”;


WHEREAS:

the Designer has given the Contractor the assignment to 		[description of the assignment, e.g.: hosting and maintenance of a website with database, keeping salary records, arranging for the dispatch of magazines, etc.], as recorded in the agreement dated ……………………….. (the “Principal Agreement”);
for the purpose of the Principal Agreement, the Contractor directly or indirectly receives data from the Designer, part of which can be traced to an identified or identifiable natural person and therefore constitutes the Processing of Personal Data that is governed by the GDPR;
since the Designer determines the purpose and means of the Processing of Personal Data, the Designer is the “Controller” as defined in the GDPR. The Contractor that performs the actual processing at the Client’s instruction is the “Processor” as defined in the GDPR; and
under Article 28(3) of the GDPR, the Processing of Personal Data must be recorded in an agreement (or other juridical act). The purpose of this Data Processing Agreement is to record the parties’ mutual rights and obligations regarding the Processing of Personal Data;

HEREBY AGREE AS FOLLOWS:
Definitions
This Data Processing Agreement uses the following definitions, which are as closely as possible in keeping with the definitions in the GDPR:

Controller 	the person in charge of the data processing: a natural person or legal entity, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the Processing of Personal Data (Article 4(7) of the GDPR);

Data Breach	a Security Incident in which Personal Data are lost or in which unlawful Processing of Personal Data cannot reasonably be precluded;

Data Processing Agreement 	this agreement, including the recitals and the schedules attached to it;

Data Subject 	an identified or identifiable natural person to whom the processed Personal Data relate (Article 4(1) of the GDPR);

GDPR	the latest version of the General Data Protection Regulation;

Personal Data 	all information on an identified or identifiable natural person (the “Data Subject”) (Article 4(1) of the GDPR);

Principal Agreement 	the agreement between the Controller and the Processor on which this Data Processing Agreement is based, in which the underlying legal relationship between the Parties is recorded, including the accompanying schedules;

Processing 	an operation or set of operations that is performed on Personal Data or on sets of Personal Data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction (Article 4(2) of the GDPR);

Processor 	a natural person or legal entity, public authority, agency or other body that processes Personal Data on behalf of the Controller (Article 4(8) of the GDPR);

Purpose 	the purpose of the Processing of the Personal Data, as defined by the Controller and prescribed to the Processor and set out in more detail in Schedule I to this Data Processing Agreement;

Security Incident	a breach of the technical and organisational measures for protection against loss of or unlawful Processing of Personal Data;

Special Personal Data	Personal Data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person’s sex life or sexual orientation (Article 9(1) of the GDPR);

Staff Members	persons working at or for the Processor, either in employment or under temporary contracts;

Sub-processor	the party that is engaged by the Processor for the Processing of Personal Data under this Data Processing Agreement; and

Third Party	a natural person or legal entity, public authority, agency or body other than the Data Subject, the Controller, the Processor or any person who is authorised to process Personal Data under the direct authority of the Controller or Processor (Article 4(10) of the GDPR).
Applicability; Processing of Personal Data
This Data Processing Agreement governs the Processing of Personal Data by the Processor with regard to the performance of the Principal Agreement. 
To perform the activities under the Principal Agreement, the Controller instructs the Processor to process Personal Data for the Controller, which instruction the Processor accepts.
The Processor declares that it adequately informed the Controller before the conclusion of the Data Processing Agreement of the Processor’s service(s) and of the Processing to be performed.
Schedule I to this Data Processing Agreement, which forms part of this Agreement, contains a list of the categories of Personal Data, the Purpose or the purposes for which the Personal Data are processed and a description of the Processing Operation(s). The Controller may make amendments to Schedule I by sending the Processor an amended Schedule I. If the Processor accepts the amendment in writing, it immediately forms part of this Data Processing Agreement.
The Processing is carried out under the responsibility of the Controller. The Controller at all times has independent control over the Purpose and means of the Processing of the Personal Data by the Processor. The Processor has no independent control over the Purpose and means of the Processing of the Personal Data, the provision of these Personal Data to Third Parties, the retention periods and the notification requirement in event of a Data Breach as referred to in Article 5 of this Data Processing Agreement.
The Processing is carried out with due care and not for a longer period or more extensively than required for the purposes of the Principal Agreement. The Processor complies with the written instructions given by the Controller in the Processing of the Personal Data and may not use them for any purposes other than for the purpose for which the Personal Data were provided or came to its knowledge, or otherwise than in accordance with the Controller’s instructions.
The Processor keeps a register of the Processing for the Controller.
The Processor appoints a Data Protection Officer if the applicable laws and regulations so require.
The Processor ensures that only Staff Members have access to the Personal Data, with the exception of Sub-processors as referred to in Article 7, and that they are aware of and are required to comply with the obligations under this Data Processing Agreement.
The Controller warrants that its instructions to the Processor result in a Processing of the Personal Data that is not contrary to the laws and regulations that apply at the time of Processing. In the event of a claim (or alleged claim) or judgment, the Controller indemnifies the Processor against all resulting liabilities. 
Security measures
The Processor takes all the appropriate technical and organisational measures required to adequately protect the Personal Data and keep them protected against loss or any form of careless, incompetent or unlawful use or Processing, taking the current state of the art and the costs of the security measures into account.
Schedule II sets out the security measures taken by the Processor. The Processor informs the Controller if one of the security measures changes significantly. 
The measures referred to in the preceding paragraph in any event include:
measures to ensure that only authorised Staff Members have access to the Personal Data that are processed under this Data Processing Agreement;
measures to protect the Personal Data against, in particular, unintentional or unlawful destruction, loss, unintentional alteration, unauthorised or unlawful storage, access or disclosure;
measures to identify weaknesses regarding the Processing of Personal Data in the systems that are used to provide services to the Controller; and
a suitable data protection policy for the Processing of the Personal Data.
The Controller may inspect whether and how the security measures are observed. The costs of such inspection are payable by the Controller, unless the audit shows that the Processor has not or not fully performed its obligations under this Data Processing Agreement. In that case the costs of the audit are payable by the Processor. The Processor is sent a copy of the inspection report. The parties make timely arrangements on the manner and time of the inspections to avoid any unnecessary interruption of the Processor’s business operations.
If the Processor fails to take appropriate technical and organisational security measures and subsequently fails to take appropriate measures within a reasonable term set by the Controller, the Controller may take those measures, or arrange for them to be taken, at the Processor’s expense, without prejudice to the Controller’s other rights under this Data Processing Agreement and/or the law.
The Processor warrants that all the Processing of Personal Data under this Data Processing Agreement (including any sub-processing) will take place within the European Economic Area (EEA). If the Processing takes place outside the EEA, the Processor will first request written consent and will make additional arrangements on that point with the Controller. 
Data Subject’s right of inspection, correction and objection
The Controller may request the Processor to cooperate in a request of the Data Subject to inspect, change or correct Personal Data or to cooperate in the protection of or the data portability of Personal Data, insofar as the Processor has access to those Personal Data.
If the Processor receives any direct or indirect request from a Data Subject, it immediately forwards it to the Controller, to be handled by the Controller itself. Only at the Controller’s express written request will the Processor handle such requests of a Data Subject.
Security Incident and Data Breach notification requirement
In the event of a Security Incident, the Processor takes all measures reasonably required to prevent or limit any (or any further) unauthorised disclosure, alteration, provision or unlawful Processing, to put an end to any breach of security measures or unlawful Processing of the Personal Data and to avoid it in the future. 
The Processor notifies the Controller of any suspected or actual Data Breach or any breach of security in which Personal Data are lost or unlawful Processing cannot be precluded as soon as possible, but no later than within 24 hours after such a Security Incident is discovered or is reported to the Processor by a Sub-processor, if any.
The Processor in any event informs the Controller of the nature of the Personal Data, the volume of Personal Data and the Data Subjects, and of the security measures taken. The Processor provides all the assistance reasonably required to gain insight into the gravity and the consequences (or possible consequences) of a Data Breach established.
The Controller itself is responsible for assessing whether the Security Incident constitutes a Data Breach and whether it is subject to notification. The Processor in no event independently reports a Data Breach to Data Subjects or to the Dutch Data Protection Authority. The Controller is always responsible for doing so.
The Processor assists and advises the Controller at its first request in the event of a notification to the Dutch Data Protection Authority and Data Subjects.
Communications
Communications and notifications under this Data Processing Agreement must be sent to the contacts named below:At the Controller:
[name of contact]
[contact details]

At the Processor:
[name of contact] [possibly: Data Protection Officer]
[contact details]

The parties inform each other in a timely manner of any change in these details.
Sub-processors
With the Controller’s prior written consent, the Processor may engage Sub-processors for certain operations, for instance if such Subprocessors have specialist knowledge or means that the Processor lacks. If this means that such Sub-processors process Personal Data of the Controller, the Processor imposes all the obligations under this Data Processing Agreement on those Sub-processors in writing. The term for notifying a Security Instance or a Data Breach as referred to in Article 5.2 for those Subprocessors may not exceed 12 hours after the first discovery.
The Controller consents in advance to the engagement of the Sub-processors referred to in Schedule III. The Controller’s consent is requested before other Sub-processors are engaged.
The consent referred to in the preceding paragraph may not be withheld on unreasonable grounds. If necessary, the Controller may set additional conditions that the Processor must impose on the Sub-processor.
If the Sub-processor fails to perform its obligations towards the Processor, the Processor is fully liable towards the Controller for the performance of the Sub-processor’s obligations and for any loss incurred.
Confidentiality
The Processor treats the Personal Data as confidential and ensures that the Staff Members and any Sub-processors who are involved in the Processing of the Personal Data are also under a duty of confidentiality. 
This duty of confidentiality does not apply if a statutory regulation or judgment requires disclosure. 
Term and Termination
The date of commencement and the date of termination of this Data Processing Agreement coincide with those of the Principal Agreement. The provisions of this Agreement remain effective to the extent necessary to perform this Agreement and insofar as they are intended to survive this Agreement. The latter category includes but is not limited to the provisions on confidentiality and disputes.
The Controller may at any time terminate this Data Processing Agreement prematurely, in isolation of the Principal Agreement.
Retention period, return and destruction of Personal Data
The Controller adequately informs the Processor of any statutory or other retention periods that apply to the Processing of Personal Data by the Processor. The Processor may not retain the Personal Data any longer than is strictly necessary, in any event not after the termination of this Data Processing Agreement or, if the Parties agree on a retention period, no longer than that retention period.
On termination of this Data Processing Agreement, the Processor transfers all Personal Data to the Controller or, if the Controller so requests, destroys the Personal Data. Copies of the Personal Data are retained only if the Processor is required to do so by law. 
The Controller pays the costs of collecting and transferring or destroying the Personal Data as referred to in the preceding paragraph. A cost estimate is made on request.
On termination of this Data Processing Agreement, the Processor informs all the Sub-processors involved in the Processing of the Personal Data of the termination of this Data Processing Agreement and requests those parties to transfer the Personal Data to the Controller or to destroy them, in accordance with the procedure set out in Article 10.2 and 10.3.
Liability
The Processor is not liable for any loss incurred by the Controller or Third Parties that is due to breach of the obligations under this Data Processing Agreement or failure of the Controller to comply with the GDPR or any other legislation or regulations. The Processor indemnifies the Controller against all claims of Sub-processors, Third Parties, Data Subjects or other persons regarding such loss and damage, and against any legal and other expenses incurred by the Controller in that context and any fines imposed on the Controller.
The parties agree that the normal statutory liability regulations apply.
Other provisions
This Data Processing Agreement constitutes a schedule to and forms an integral part of the Principal Agreement between the Parties. In the event of inconsistency between the Principal Agreement and this Data Processing Agreement, the latter prevails.
Amendments to one or more provisions of this Data Processing Agreement may be agreed by the Parties only in writing. 
If any provision of this Data Processing Agreement is void or is declared void, the other provisions of this Agreement remain fully effective. In that case the Parties will consult to replace the invalid provisions with provisions that approximate the objective and purport of the invalid provisions as closely as possible.
This Data Processing Agreement is governed by Dutch law. 
The court in the district in which the Controller is located or, at the Controller’s option, the court that has jurisdiction by law has jurisdiction to hear and decide on any dispute arising from this Data Processing Agreement.


Agreed, drawn up in duplicate originals and signed:

in 		on	


			
Designer/Controller		Contractor/Processor


Schedules:
Schedule I: Personal data to be processed, purposes and description of processing operation(s)
Schedule II: Security measures
Schedule III: Sub-processors
</p>
    </article>
)

export default Privacy