import styled from 'styled-components';
import Button from '../components/Button';
import bgImg from '../assets/images/richardhotline-1.jpg';
import {Link} from 'react-router-dom';
import Alert from '../components/Alert/Alert.jsx';

const Main = styled.main`
    background: url('${bgImg}');
    background-size: cover;
    height: 100vh;
`;

const TopNotification = styled.div`
    background: var(--background-dark);
    color: var(--gray);
    margin: 0;
    padding: .4em .75em;
    font-size: var(--small-font-size);
`;

const Start = () => (
    <>
    <TopNotification>This messaging service is out of order due to maintenance. Please come back later.</TopNotification>
    <Main>
        <div className="container">
            <h1>Richard Hotline</h1>
            <p>Does it sometimes seem like Richard has dissapeared from the world?
                Doesn't he awnser his phone? Neither his email? He might be buzzy
                behind his computer and not really thirsty for digital messages.
                So, let's make some analog ones. Unfortunantly, postcards are quite slow.
                Our solotion: drop your message here and we will print it out
                and put it in front of his face.</p>
            <Button to="/form">Let's start!</Button>
            <Link to='/privacy'>Privacy policy</Link>
        </div>
        
    </Main>
    </>
)

export default Start;