import { useState, useReducer } from 'react';
import { ImagesContext, ImagesDispatchContext, imagesReducer } from '../../lib/imageContext';

import {
    FormInput, 
    FormTextArea, 
    FormBase, 
    FormHeader, 
    FormWrapper, 
    Cross, 
    CounterBase
} from './Form.styles.jsx'
import Button from '../../components/Button.jsx';
import WordCount from '../../components/WordCount.jsx';
import Alert from '../../components/Alert/Alert.jsx';
import ImageContainer from '../../components/UploadImages/UploadImages.jsx'

const Form = ({maxTextFieldLength}) => {

    const [stage, setStage] = useState(1)

    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [message, setMessage] = useState(null);
    const [imageFiles, dispatch] = useReducer(imagesReducer, [])
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        
        console.log('submitting...')
        console.log(imageFiles)
        e.preventDefault();
        setStage(2)    

    
        const messageData = {
            name: name,
            contact: email,
            message: message,
            images: [],
            created: new Date()
        }
       
        try {
            // if(imageFiles) {
            //     imageFiles.forEach((img, index) => {
            //         console.log(`Uploading image ${index} of ${images.length}`)
            //         const {images} = messageData;

            //         const data = {
            //             user: 'Richard',
            //             img: img.file
            //         }

            //         const resp = await fetch('localhost:3000/api/upload-image', {
            //             method: 'POST',
            //             body: JSON.stringify(data)
            //         })

            //         if(resp.status != 200) {
            //             throw new Error(resp)
            //         }

            //         images.push()
            //     })
            // }
            
            const submit = await fetch('localhost:3000/api/add', {
                method: 'POST',
                body: JSON.stringify(messageData),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            if (await submit) {
                console.log(submit)
                setStage(3)    
            } else {
                // throw new EvalError('The message service is currently not available.');
            }
            
        } catch(error) {
            console.error(error); 
            setError(error)
        }
        
        // setTimeout(()=> , 2000)


    }



    const Editor = () => (
    <ImagesContext.Provider value={imageFiles}>
        <ImagesDispatchContext.Provider value={dispatch}>
        <FormBase method="post" onSubmit={handleSubmit}>
                <FormHeader>
                    <h1>Send a message</h1>
                    <Cross to='/' />
                </FormHeader>
                
                <FormInput
                    name="name"
                    maxLength={200}
                    placeholder="Your name"
                    onChange={e => setName(e.target.value)}
                />
                <FormInput
                    name="name"
                    maxLength={200}
                    placeholder="Email"
                    onChange={e => setEmail(e.target.value)}
                />
                
                <FormTextArea
                    name="message"
                    placeholder="Your message..."
                    onChange={e => {
                        setMessage(e.target.value);
                    }}
                />
                <ImageContainer/>
                <div>
                    <Button href="#">Send</Button>
                    <WordCount max={500}/>
                </div>
            </FormBase>
        </ImagesDispatchContext.Provider>
    </ImagesContext.Provider>
    )

    const Loader = () => (
        <h1>Submitting...</h1>
    )

    const Completed = () => (
        <div>
            <h1>Success!</h1>
            <h2>Your message is being printed</h2>
            <p>Your work here is done. We are going to deliver your message as soon as possible. </p>
            <Button to='/'>OK</Button>
        </div>
    )

    const Error = ({error}) => {
        console.error(error)
        return (
            <Alert 
            title={error.message}
            subtitle={error.name} 
            message={"Message delivery failed. Try again later"}
            >
                <Button onClick={handleSubmit}>Retry</Button>
                <Button onClick={()=> {
                    setError(null);
                    setStage(1);
                }}>Back</Button>
            </Alert>
        )
    }

    const handleKeyDown = (e) => {
        console.log(e);
    }

    
    
    
    return (
        <FormWrapper onKeyPress={e => e.key == 'escape' && console.log('quit!')}>
            {stage == 1 && <Editor/>}
            {stage == 2 && <Loader/>}
            {stage == 3 && <Completed/>}
            {error && <Error error={error} />}
        </FormWrapper>
    )
}

export default Form;

