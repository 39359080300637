
import styled, {keyframes} from 'styled-components';
import cross from '../../assets/icons/close.svg'

export const FileDropArea = styled.div`
    padding: 0em;
    width:100%;
    margin-bottom: 1em;
    min-height: 10em;
    border: #098659 0.15rem solid;
    resize: vertical;
    background: rgba(12, 142, 96, 0.95);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ItemWrapper = styled.div`
  position: relative;
`;

const DeleteWrapper = styled.a`
  box-shadow: #4009409c -0.2em 0.25em 0.7em 3px;
  position: absolute;
  height: auto;
  top: 1em;
  right: 1em;
  border-radius: 100vw;
  border: 2px solid var(--gray);
  background: var(--white);
  margin: 0;
  padding: 0 .5em;
  cursor: pointer;
  z-index: 5;
`;

const DeleteIcon = styled.img`
  width: 0.3em;
  height: auto
`;

export const Delete = (props) => (
  <DeleteWrapper {...props}>
    <DeleteIcon style={{width: '.66em'}} src={cross}/>
  </DeleteWrapper>
);

export const NotificationWrapper = styled.div`
    position: fixed;
    right: 3rem;
    top: 0;
    width: min(80vw, 30rem);
    height: 100px;
    z-index: 5;
`;

export const progressBar = keyframes`
  from {transform: 0%}
  to {transform: 100%}
`;

const breatheAnimation = keyframes`
 0% { height: 100px; width: 100px; }
 30% { height: 400px; width: 400px; opacity: 1 }
 40% { height: 405px; width: 405px; opacity: 0.3; }
 100% { height: 100px; width: 100px; opacity: 0.6; }
`

export const NotificationElement = styled.dialog`
  position: relative;
  top: 0;
  left: 0;
  margin-right: 2em;
  margin-top: 1em;
  padding: 0.5em;
  width: min(80vw, 30rem);
  display: block;
  border-radius: 10px;
  
  p {
    margin-bottom: 0.5em
  }
  &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 5px;
      background: var(--black)
      //animation-name: ${breatheAnimation} 8s linear infinite;
      animation-name: rotateslide 8s linear infinite;
    }
  }
`