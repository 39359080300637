import Button from '../Button.jsx';
import {useState} from 'react';


const Alert = ({title, subtitle, message, children}) => {
  const [isActive, setIsActive] = useState(true);
  if(isActive) {
    return (
    <dialog open>
      <h3>{subtitle}</h3>
      <h1>{title}</h1>
      <p>{message}</p>
      {children || <Button onClick={()=>setIsActive(false)}>OK</Button>}               
    </dialog>
  )
  }
  
}
  
export default Alert;