import { Reorder } from "framer-motion";
import { useState, useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid';
import { ImagesContext, ImagesDispatchContext } from '../../lib/imageContext';
import {
  FileDropArea,
  NotificationWrapper,
  NotificationElement,
  Delete,
  ItemWrapper
} from './UploadImages.styles.jsx'

export default function Images() {
  const [isGrayscale, setGrayscale] = useState(true);
  const [fileErrors, setFileErrors] = useState([]);
  const imageFiles = useContext(ImagesContext);
  const dispatch = useContext(ImagesDispatchContext);

  const filetypes = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/webp': ['.webp'],
    'image/bmp': ['.bmp']
  }

  const exeedsMaxLength = () => {
    const max = 3
    if(imageFiles.length >= max) {
      console.log(`Max ${max} photos!`)
      return {
        mssg: `Max ${max} photos!`
      }
    } else {
      return null
    }
  }

  const Notification = ({timer, id, children}) => {

  return (
    <NotificationElement open key={id}>
      {children}
    </NotificationElement>
  )
}
  const Placeholder = () => (
    <div>
        <p>Click or drag to add photos</p>
    </div>
  )

  const GalleryItem = ({src, index}) => {
    const {width, height} = {
      width: 400,
      height: 400
    }

    const GetCanvas = (img) => {
      return (
        <Reorder.Item className="no-spacing" as="div" key={index} value={index}>
          <img src={img} />
        </Reorder.Item>
      )
    }

    const handleDelete = (e, index) => {
      e.stopPropagation()
      // setImageFiles(
      //   imageFiles.filter(img => img.id !== index)
      // )
      dispatch({
        type: 'deleted',
        id: index
      })
    }

    return (
      <ItemWrapper>
        <Delete onClick={handleDelete}>x</Delete>
        {GetCanvas(src)}
      </ItemWrapper>
    )
  }

  const Gallery = () => {
    return (
      <Reorder.Group onReorder={dispatch} className="no-spacing" values={imageFiles} as="div">
        {imageFiles.map(({id, file}) => (  
          <GalleryItem index={id} key={id} src={file} />
        ))}
      </Reorder.Group>
    )
  }

  let nextId = 0;

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      // const reader = new FileReader()
      const uri = URL.createObjectURL(file)

      dispatch({
        type: 'added',
        id: nextId++,
        file: uri
      })
      
      // setImageFiles(imageFiles => [
      //   ...imageFiles,
      //   {id: nextId++, file: uri}
      // ])
      console.log(imageFiles)      
    })
    console.log(imageFiles)

    fileRejections.forEach(rejected => {
      const {file, errors} = rejected
      // console.log(rejected)
      const getErrorMssg = (code) => {
        if(code == "file-invalid-type") {
          return "You can only upload pictures"
        }
        if(code == "file-too-large") {
          return "Only files smaller than 10MB are allowed"
        }
        if(code == "too-many-files") {
          return "You can upload a maximum of 3 images."
        }
        return code
      }
      errors.forEach(error => {
     
        setFileErrors([...fileErrors, {
          id: uuidv4(),
            file: file.name,
            message: getErrorMssg(error.code)
        }])
      })
      console.log(fileErrors)
    })

    
  }, [])

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: onDrop, 
    maxSize: 10000000, //bytes
    accept: filetypes,
    maxFiles: 3
  })

  return (
    <>
    <FileDropArea {...getRootProps()}>
      <input {...getInputProps()}/>
      {imageFiles.length <= 0 ? <Placeholder/> : <Gallery/>}
    </FileDropArea>
    <input 
      checked={isGrayscale}
      onClick={e => {
      setGrayscale(!isGrayscale)
      }} type="checkbox"/>
    <label>Preview print colors</label>
    <NotificationWrapper>      
      {fileErrors.map((err, index) => (
      <Notification id={err.id} timer={5000} open>
        <h4>{err.file}</h4>
        <p>{err.message}</p>
      </Notification>
      ))}
    </NotificationWrapper>
    </>
  )
}