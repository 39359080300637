import React from 'react';
import ReactDOM from 'react-dom/client';
import Start from './pages/Start';
import Form from './pages/Form/Form';
import Privacy from './pages/Privacy/Privacy';
import Error404 from './pages/404/Error404';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, useRouteError } from 'react-router-dom';
import Button from './components/Button';

import './styles/reset.scss';
import './styles/import-fonts.scss';
import './styles/variables.scss';
import './styles/typography.scss';
import './styles/components.scss';

const Error = () => {
  let error = useRouteError();
  console.error(error);
  return (
    <dialog style={{maxWidth: '40em'}} open>
      <h3>{error.name}</h3>
      <h2>{error.message}</h2>
      <Button small to="/">Back</Button>
    </dialog>
    
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Start/>,
    errorElement: <Error404/>,
  },
  {
    path: 'form',
    element: <Form/>,
    errorElement: <Error/>
  },
  {
    path: 'privacy',
    element: <Privacy/>,
    errorElement: <Error/>
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
