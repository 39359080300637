import styled, {css} from 'styled-components';
import { useState } from 'react';
import {Link} from 'react-router-dom';

const defaultStyling = css`
    border: 0.15rem solid black;
    padding: 0.5em 2em;
    margin-top: .5em;
    margin-right: 0.5em;
    box-shadow: 0.3em 0.3em teal;
    transition: 0.1s ease;
    background-color: white;
    display: inline-block;
    color: var(--text-color);
    cursor: pointer;
    &:hover {
        box-shadow: 0.2em 0.2em teal;
    }
    &:active {
        background-color: whitesmoke;
        box-shadow: 0.1em 0.1em teal;
        border: 0.15rem solid gray;
        color: gray;
    }
`

const LinkBase = styled(Link)`
    ${defaultStyling}
`

const ButtonBase = styled.button`
    ${defaultStyling}
`;

const DisabledBtn = styled(LinkBase)`
    cursor: not-allowed;
    background-color: whitesmoke;
    border: 0.15rem solid gray;
    color: gray;
    &:hover {
        background-color: whitesmoke;
        box-shadow: 0.1em 0.1em teal;
        border: 0.15rem solid gray;
        color: gray;
    }
`

const LoadingBtn = styled(LinkBase)`
    cursor: progress;
    background-color: whitesmoke;
    box-shadow: 0.2em 0.2em teal;
    border: 0.15rem solid gray;
    color: gray;
    padding: 0.5em 2em 0.2em 2em
    &:hover {
        background-color: whitesmoke;
        box-shadow: 0.2em 0.2em teal;
        border: 0.15rem solid gray;
        color: gray;
    }
`

const Button = ({children, to, onClick, disabled, type, small}) => {
    if (disabled) {
        return <DisabledBtn className={small && 'small'}>{children}</DisabledBtn>
    } else {
        if (to) {
            return <LinkBase  className={small && 'small'} to={to}>{children}</LinkBase>
            
        } else {
            return <ButtonBase className={small && 'small'} onClick={onClick}>{children}</ButtonBase>
        }
        
    }
}

export default Button;