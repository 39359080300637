import styled from "styled-components";
import {Link} from 'react-router-dom';
import cross from '../../assets/icons/close.svg'
export const FormInput = styled.input`
    padding: 1em;
    width:100%;
    margin-bottom: 1em;
    border: black 0.15rem solid;
    color: ${props => props.error ? "red" : "black"};
`
export const FormTextArea = styled.textarea`
    width:100%;
    margin-bottom: 1em;
    min-height: 10em;
    border: black 0.15rem solid;
    resize: vertical;
    padding: 1em;
`
export const FormBase = styled.form`
    margin: 1em;
    width:100%;
    /* position: absolute;
    left: 0;
    top: 0; */
    max-width: 50rem;
`
export const FormHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2em 0;
    h1, h2, h3, h4, h5, h6, p, a {
        margin: 0;
        padding: 0;
    }
`
export const FormWrapper = styled.section`
    background: rgba(0,155,100,0.95);
    margin: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 1s ease-in-out;
    animation: fadeIn 0.35s;
    @keyframes fadeIn {
        0%{
            opacity: 0;
            scale(0);
        }

        100%{
            opacity: 1;
            transform: scale(1);
        }
    }
`;
const CrossWrapper = styled(Link)`
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    color: var(--text-color);
`
export const Cross = (props) => (
    <CrossWrapper {...props}>
        <img src={cross}/>
    </CrossWrapper>
)

export const CounterBase = styled.p`
    display: flex;
    justify-content: space-between;
    color: red;
`

