import { createContext } from 'react';

export const ImagesContext = createContext([])
export const ImagesDispatchContext = createContext(null)

export const imagesReducer = (imageFiles, action) => {
  switch(action.type) {
    case 'added': {
      return [
        ...imageFiles,
        {
          id: action.id,
          file: action.file
        }
      ]
    };
    case 'deleted': {
      return imageFiles.filter(img => img.id != action.id)
    };
    default: {
      throw Error(`Unknown action ${action.type}`)
    };
  }
}