import styled from 'styled-components';
import { useState } from 'react';

const CounterBase = styled.p`
    display: inline-flex;
    justify-self: end;
    align-self: normal;
    float: right;
    transform: translateY(-0.3em);
    color: ${props => props.inputColor || "inherit"}
`

const WordCount = ({count, max}) => {
    return (
        <CounterBase inputColor={ (max - count) < 0 ? "palevioletred" : "black"}>
            <p className='small'>${max - count}</p>
        </CounterBase>
    )
}

export default WordCount